//选择章节
<template>
  <div class="choose_chapter">
    <div class="content" v-if="!showLoading">
      <!-- 返回和章节名称 -->
      <div class="top_msg">
        <div @click.stop="goBack" class="back iconfont cloud-fanhui"></div>
        <div class="name">{{ curriculumName }}</div>
      </div>

      <!-- 展示章节的内容 -->
      <div class="show_chapter">
        <div
          :class="[(index + 1) % 2 != 0 ? 'hasMargin' : 'noMargin', 'item_chapter']"
          v-for="(item, index) in chapterData"
          :key="index"
          @mouseover="item.isShow = true"
          @mouseout="item.isShow = false"
          @click.stop="goChooseLesson(item)"
        >
          <!-- 左边的名称 -->
          <div class="left_name" :class="[{ bg_left_one: item.types == 1 }, { bg_left_two: item.types == 2 }, { bg_left_three: item.types == 3 }]">
            {{ item.type == 1 ? "体验课" : "正式课" }}
          </div>

          <!-- 右边的类型 -->
          <div class="right_type" :class="[{ bg_right_one: item.types == 1 }, { bg_right_two: item.types == 2 }, { bg_right_three: item.types == 3 }]">
            {{ item.chapterName }}
          </div>

          <!--  蒙版层 -->
          <div class="mengban" v-show="item.isShow"></div>
        </div>
      </div>
    </div>
    <!-- 加载框 -->
    <Loading v-else></Loading>
  </div>
</template>
<script>
export default {
  name: "ChooseChapter",
  data() {
    return {
      //是否显示加载框
      showLoading: true,

      //课程名称
      curriculumName: "",

      //课程ID
      curriculumCenterId: "",

      //章节数据
      chapterData: []
    };
  },

  activated() {},

  created() {
    // 请求数据
    this.httpData();
  },

  methods: {
    //选择课程
    goChooseLesson(item) {
      const obj = new Object();
      obj.curriculumCenterId = this.curriculumCenterId;
      obj.curriculumChapterId = item.curriculumChapterId;
      obj.chapterType = item.chapterType;
      obj.chapterName = item.chapterName;
      this.$router.push({
        path: "/LoginContain/ChooseLesson",
        query: obj
      });
    },

    //返回
    goBack() {
      this.$router.back(-1);
    },

    //获取列表数据
    async httpData() {
      //携带的Id
      let postId = this.$route.query.curriculumCenterId;
      this.curriculumName = this.$route.query.curriculumName;
      // if (postId != this.curriculumCenterId) {
      //解决页面后退的时候刷新数据
      this.curriculumCenterId = this.$route.query.curriculumCenterId;
      //获取传递的参数
      this.showLoading = true;
      // }

      let param = this.getHttpParams();
      param.type = 2;
      param.curriculumCenterId = this.curriculumCenterId;
      let res = await this.$http.fetchPost(this.$api.LESSON_CENTER, param);
      if (res.data.state == 200) {
        //改变加载状态
        this.showLoading = false;

        //表示请求成功
        this.chapterData = res.data.data;

        //循环给每个条目加入一个响应式的控制遮罩的值
        this.chapterData.forEach(item => {
          this.$set(item, "isShow", false);
        });
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    }
  },
  computed: {},
  components: {}
};
</script>
<style lang="scss" scoped>
.choose_chapter {
  background: $common_gray;
  height: calc(100vh - 87px);
  overflow-y: auto;

  .content {
    padding-top: 20px;
    padding-bottom: 20px;
    width: $common_width;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    //顶部返回与章节名称
    .top_msg {
      height: 55px;
      display: flex;
      align-items: center;
      .back {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 22px;
        font-weight: bold;
        height: 36px;
        width: 36px;
        background: rgba($color: #43bb61, $alpha: 0.6);
        border-radius: 50%;
      }

      .name {
        margin-left: 14px;
        padding: 0 10px;
        min-width: 184px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background: $common_bg;
        border-radius: 22px;
      }
    }

    //展示章节的内容
    .show_chapter {
      margin-top: 12px;
      display: flex;
      flex-wrap: wrap;
      .item_chapter {
        margin-bottom: 28px;
        position: relative;
        width: 568px;
        height: 150px;
        border-radius: 8px;
        align-items: center;
        display: flex;
        cursor: pointer;
        .img_chapter {
          width: 140px;
          height: 112px;
          margin-left: 42px;
        }

        //标题和内容介绍
        .title_content {
          margin-left: 16px;
          color: black;
          .item_title {
            font-size: 18px;
            font-weight: bold;
          }
          .item_content {
            margin-top: 20px;
            font-size: 16px;
          }
        }

        //左边的课程名称
        .left_name {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          width: 80px;
          font-size: 18px;
          color: white;
          font-weight: bold;
          letter-spacing: 8px;
          writing-mode: vertical-lr;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        //右边的类型
        .right_type {
          flex: 1;
          color: white;
          font-size: 24px;
          height: 100%;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        //蒙版
        .mengban {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          position: absolute;
          background: rgba($color: #000000, $alpha: 0.2);
        }
      }

      .bg_left_one {
        background: #f7a901;
      }

      .bg_left_two {
        background: #1ca63d;
      }

      .bg_left_three {
        background: #0068b7;
      }

      .bg_right_one {
        background: #fdd931;
      }

      .bg_right_two {
        background: #5ac974;
      }

      .bg_right_three {
        background: #3e99dd;
      }

      .hasMargin {
        margin-right: 60px;
      }

      .noMargin {
        margin-right: 0px;
      }
    }
  }
}
//去除滚动条
.choose_chapter::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>
